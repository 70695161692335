// Generated by Framer (8184e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./xk9V7LKBf-0.js";

const enabledGestures = {q2L5ZPLJj: {hover: true}};

const cycleOrder = ["q2L5ZPLJj", "mWDByK2ea"];

const serializationHash = "framer-qV9Uf"

const variantClassNames = {mWDByK2ea: "framer-v-13wzz02", q2L5ZPLJj: "framer-v-1ptk3b0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {nSIDtA4sZ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"burger-close": "mWDByK2ea", burger: "q2L5ZPLJj"}

const getProps = ({height, hover, id, tap, width, ...props}) => { return {...props, jv9vRuQl9: hover ?? props.jv9vRuQl9, reToirgn_: tap ?? props.reToirgn_, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "q2L5ZPLJj"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jv9vRuQl9, reToirgn_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "q2L5ZPLJj", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapv6ax7j = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (reToirgn_) {
const res = await reToirgn_(...args);
if (res === false) return false;
}
})

const onMouseEnter1wm33nz = activeVariantCallback(async (...args) => {
if (jv9vRuQl9) {
const res = await jv9vRuQl9(...args);
if (res === false) return false;
}
})

const onTap1phslew = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (reToirgn_) {
const res = await reToirgn_(...args);
if (res === false) return false;
}
setVariant("q2L5ZPLJj")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ptk3b0", className, classNames)} data-framer-name={"burger"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"q2L5ZPLJj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1wm33nz} onTap={onTapv6ax7j} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"q2L5ZPLJj-hover": {"data-framer-name": undefined}, mWDByK2ea: {"data-framer-name": "burger-close", onTap: onTap1phslew}}, baseVariant, gestureVariant)}><motion.div className={"framer-1to6eto"} data-framer-name={"bottom"} layoutDependency={layoutDependency} layoutId={"qEWxYne8g"} style={{backgroundColor: "var(--token-5cda9d78-e221-437f-8a8e-ba57e942142e, rgb(33, 170, 226))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, rotate: 0}} variants={{mWDByK2ea: {rotate: -45}}}/><motion.div className={"framer-10jney4"} data-framer-name={"middle"} layoutDependency={layoutDependency} layoutId={"e9hXSK0Qw"} style={{backgroundColor: "rgb(194, 14, 26)", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, opacity: 1}} variants={{mWDByK2ea: {opacity: 0}}}/><motion.div className={"framer-q06e9p"} data-framer-name={"top"} layoutDependency={layoutDependency} layoutId={"ORknHztvr"} style={{backgroundColor: "var(--token-6b667689-1e80-4fb7-a768-4cf19622f98b, rgb(137, 190, 73))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, rotate: 0}} variants={{mWDByK2ea: {rotate: 45}}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-qV9Uf[data-border=\"true\"]::after, .framer-qV9Uf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qV9Uf.framer-9qfenj, .framer-qV9Uf .framer-9qfenj { display: block; }", ".framer-qV9Uf.framer-1ptk3b0 { cursor: pointer; height: 36px; overflow: visible; position: relative; width: 36px; }", ".framer-qV9Uf .framer-1to6eto { bottom: 7px; flex: none; height: 3px; left: calc(50.00000000000002% - 30px / 2); overflow: visible; position: absolute; width: 30px; }", ".framer-qV9Uf .framer-10jney4 { flex: none; height: 3px; left: calc(50.00000000000002% - 30px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 3px / 2); width: 30px; }", ".framer-qV9Uf .framer-q06e9p { flex: none; height: 3px; left: calc(50.00000000000002% - 30px / 2); overflow: visible; position: absolute; top: 7px; width: 30px; }", ".framer-qV9Uf.framer-v-13wzz02.framer-1ptk3b0, .framer-qV9Uf.framer-v-1ptk3b0.hover.framer-1ptk3b0 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 36px); }", ".framer-qV9Uf.framer-v-13wzz02 .framer-1to6eto { bottom: unset; top: calc(50.00000000000002% - 3px / 2); }", ".framer-qV9Uf.framer-v-13wzz02 .framer-q06e9p { top: calc(50.00000000000002% - 3px / 2); }", ".framer-qV9Uf.framer-v-1ptk3b0.hover .framer-1to6eto { bottom: 5px; }", ".framer-qV9Uf.framer-v-1ptk3b0.hover .framer-q06e9p { top: 5px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"mWDByK2ea":{"layout":["fixed","fixed"]},"OKoZuJoYv":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"jv9vRuQl9":"hover","reToirgn_":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerxk9V7LKBf: React.ComponentType<Props> = withCSS(Component, css, "framer-qV9Uf") as typeof Component;
export default Framerxk9V7LKBf;

Framerxk9V7LKBf.displayName = "burger";

Framerxk9V7LKBf.defaultProps = {height: 36, width: 36};

addPropertyControls(Framerxk9V7LKBf, {variant: {options: ["q2L5ZPLJj", "mWDByK2ea"], optionTitles: ["burger", "burger-close"], title: "Variant", type: ControlType.Enum}, jv9vRuQl9: {title: "Hover", type: ControlType.EventHandler}, reToirgn_: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerxk9V7LKBf, [])